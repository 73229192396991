export const Smartphone = ({ ...props }) => {
  return (
    <svg
      {...props}
      version="1.1"
      id="Outline"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M18.6,5.2V3.7c0-1-0.8-1.7-1.7-1.7H7.2c-1,0-1.7,0.8-1.7,1.7v1.4C3.8,5.5,2,6.2,2,7.3v10.3c0,0.7,0.8,1.3,2.3,1.8
		c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.3v-2.7c0.2-0.1,0.4-0.1,0.7-0.2v4.1c0,1,0.8,1.7,1.7,1.7h9.7
		c1,0,1.7-0.8,1.7-1.7v-4.1c0.2,0.1,0.5,0.1,0.7,0.2v2.7c0,0.1,0.1,0.2,0.1,0.3c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0
		c1.5-0.5,2.3-1.1,2.3-1.8V7.3C22,6.2,20.2,5.5,18.6,5.2L18.6,5.2z M14.1,2.7V3c0,0.2-0.2,0.3-0.3,0.3h-3.4c-0.2,0-0.3-0.2-0.3-0.3
		V2.7H14.1z M7.2,2.7h2.1V3c0,0.6,0.5,1,1,1h3.4c0.6,0,1-0.5,1-1V2.7h2.1c0.6,0,1,0.5,1,1v14.8H6.1V3.7C6.1,3.2,6.6,2.7,7.2,2.7z
		 M4.1,18.6c-1-0.4-1.4-0.8-1.4-1V8.4C3,8.6,3.5,8.8,4.1,9V18.6z M4.8,15.7V8.8c0-0.2-0.1-0.3-0.2-0.3C3,8,2.8,7.5,2.7,7.4
		c0,0,0,0,0,0V7.3c0-0.3,0.6-0.9,2.8-1.4v9.6C5.2,15.6,5,15.6,4.8,15.7L4.8,15.7z M17.9,20.3c0,0.6-0.5,1-1,1H7.2c-0.6,0-1-0.5-1-1
		v-1h11.7V20.3z M21.3,17.6c0,0.2-0.4,0.6-1.4,1V9c0.6-0.2,1-0.4,1.4-0.7V17.6L21.3,17.6z M21.3,7.3C21.3,7.3,21.3,7.3,21.3,7.3
		c0,0.2-0.3,0.6-1.8,1.1c-0.1,0-0.2,0.2-0.2,0.3v6.9c-0.2-0.1-0.4-0.1-0.7-0.2V5.9C20.7,6.4,21.3,7,21.3,7.3L21.3,7.3z"
        />
        <path
          d="M8.4,13.6l3.4,2c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0l3.4-2c0.1-0.1,0.2-0.2,0.2-0.3v-4c0-0.1-0.1-0.2-0.2-0.3l-3.4-2
		C12.1,7,11.9,7,11.8,7L8.4,9C8.3,9.1,8.2,9.2,8.2,9.3v4C8.2,13.4,8.3,13.5,8.4,13.6z M8.9,9.9l2.8,1.6v3.2l-2.8-1.6V9.9z
		 M12.3,14.7v-3.2l2.8-1.6v3.2L12.3,14.7z M12,7.7l2.8,1.6L12,10.9L9.2,9.3L12,7.7z"
        />
      </g>
    </svg>
  );
};
