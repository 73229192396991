import { FC, useEffect, useState } from "react";
import s from "./Interface.module.scss";
import { SelectComponent } from "../SelectComponent/SelectComponent";
type InterfaceT = {};

export const Interface: FC<InterfaceT> = () => {
  //   const [allValues, setAllValues] = useState({});
  const threeKitAttr =
    //@ts-ignore
    window.playerT.configurator.getDisplayAttributes();

  //   useEffect(() => {
  //     const values: any = {};
  //     threeKitAttr.map((item: any) => {
  //       values[item.name] = item.defaultValue;
  //     });
  //     setAllValues(values);
  //   }, []);
  return (
    <div className={s.wrapper}>
      {threeKitAttr.map((attr: any, index: number) => {
        return (
          <SelectComponent
            nameConfigurator={attr.name}
            options={attr}
            key={index}
          />
        );
      })}
      {/* <div
        className="resetHint"
        onClick={() => {
          sessionStorage.setItem("hideInstructions", `${false}`);
        }}
      >
        reset Hint
      </div> */}
    </div>
  );
};
