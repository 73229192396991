import React from "react";
import { useDispatch, useSelector } from "react-redux";

import s from "./ModalFrame.module.scss";
import { ModalStatusT, NameModals } from "../../../redux/reducers/reducer";
import { changeShowModal } from "../../../redux/actions";
import { getStateModal } from "../../../redux/selectors/selectors";
import { ModalHint } from "../ModalHint/ModalHint";
export const ModalFrame = () => {
  const dispatch = useDispatch();
  let localHideInstructions = sessionStorage.getItem("hideInstructions");

  if (localHideInstructions === null || localHideInstructions === undefined) {
    localHideInstructions = "false"
  }

  const checkIsLocalInstructionsHide =
    localHideInstructions && localHideInstructions === "true";
  const closeFunc = (name: NameModals) => {
    dispatch(changeShowModal({ nameModal: name, stateModal: false }));
  };

  const hint: { name: NameModals; data: ModalStatusT } = useSelector(
    getStateModal("hint")
  );
  let currentUrl = window.location.href;

  let checkIsOpenHint =
    hint.data["isShow"] &&
    !checkIsLocalInstructionsHide &&
    !currentUrl.includes("hintHide=true");

  if (!checkIsLocalInstructionsHide) {
    if (currentUrl.includes("showHint=true")) {
      checkIsOpenHint = false
    }
  }
   
  console.log('checkIsOpenHint', { showHint:currentUrl.includes("showHint=true"), hint: hint.data["isShow"], checkIsLocalInstructionsHide, currentUrl, checkIsOpenHint });
  console.log('checkIsOpenHint', checkIsOpenHint);


  return (
    <>
      {checkIsOpenHint && (
        <div className={s.modal_bg}>
          <ModalHint closeFunc={() => closeFunc(hint.name)} />
        </div>
      )}
    </>
  );
};
