export const ArrowCircle = ({ ...props }) => {
  return (
    <svg
      {...props}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M12.4,18.5c-0.2,0-0.3-0.1-0.5-0.2c-0.3-0.3-0.3-0.7,0-0.9l1.5-1.5c-0.5,0-1,0-1.5,0c-2.9,0-5.7-0.4-7.8-1.1
                C3,14.4,2.2,14,1.6,13.5c-0.7-0.6-1.1-1.3-1.1-2s0.4-1.4,1.1-2C2.2,9,3,8.6,4.2,8.2C6.4,7.4,9.2,7,12.1,7s5.7,0.4,7.8,1.1
                c1.1,0.4,1.9,0.8,2.5,1.3c0.7,0.6,1.1,1.3,1.1,2s-0.4,1.4-1.1,2c-0.6,0.5-1.5,1-2.6,1.3c-0.1,0-0.1,0-0.2,0l0,0
                c-0.3,0-0.5-0.2-0.6-0.4c-0.1-0.3,0.1-0.7,0.4-0.8c1.7-0.6,2.8-1.4,2.8-2.1s-1-1.5-2.7-2.1c-2-0.7-4.6-1.1-7.4-1.1S6.6,8.6,4.6,9.3
                c-1.7,0.6-2.7,1.4-2.7,2.1c0,0.7,1,1.5,2.7,2.1c2,0.7,4.6,1.1,7.4,1.1c0.5,0,1,0,1.5,0L11.9,13c-0.3-0.3-0.3-0.7,0-0.9
                c0.1-0.1,0.3-0.2,0.5-0.2s0.3,0.1,0.5,0.2l2.5,2.5c0.3,0.3,0.3,0.9,0,1.2l-2.5,2.5C12.8,18.4,12.6,18.5,12.4,18.5z"
        />
        <g>
          <path
            d="M12.6,6.5L12.6,6.5c-0.3,0-0.6-0.3-0.6-0.6V2c0-0.3,0.3-0.6,0.6-0.6h0c0.3,0,0.6,0.3,0.6,0.6v4
                    C13.2,6.2,12.9,6.5,12.6,6.5z"
          />
          <path
            d="M12.6,11L12.6,11c-0.3,0-0.6-0.3-0.6-0.6V9.3c0-0.3,0.3-0.6,0.6-0.6h0c0.3,0,0.6,0.3,0.6,0.6v1.2
                    C13.2,10.8,12.9,11,12.6,11z"
          />
          <path
            d="M12.6,22.6L12.6,22.6c-0.3,0-0.6-0.3-0.6-0.6v-2.7c0-0.3,0.3-0.6,0.6-0.6h0c0.3,0,0.6,0.3,0.6,0.6V22
                    C13.2,22.4,12.9,22.6,12.6,22.6z"
          />
        </g>
      </g>
    </svg>
  );
};
