import { TYPE_REDUCER } from "../../utils/constant";

export const changeShowModal = ({ ...data }) => {
  return {
    type: TYPE_REDUCER.SET_MODAL,
    payload: data,
  };
};
export const changeScrollIndicarots = ({ ...data }) => {
  return {
    type: TYPE_REDUCER.SET_SCROLL_INDICATORS,
    payload: data,
  };
};

