import { FC, useEffect, useState } from "react";
import s from "./SelectComponent.module.scss";
import Select from "react-select";
 
type SelectComponentT = {
  options: any;
  nameConfigurator: string;
};
export const SelectComponent: FC<SelectComponentT> = ({ ...props }) => {
  const { options, nameConfigurator } = props;
  const [selectedOption, setSelectedOption]: any = useState(null);

  useEffect(() => {
    if (selectedOption) {
      //@ts-ignore
      window.configurator.setConfiguration({
        [nameConfigurator]: selectedOption.value,
      });
    }
  }, [selectedOption]);
  return (
    <Select
      // styles={customStyles}
      className={s.select}
      defaultValue={{ label: options.defaultValue }}
      onChange={setSelectedOption}
      options={options.values}
    />
  );
};
