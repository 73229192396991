import { TYPE_REDUCER } from "../../utils/constant";

export type NameModals = "hint";
export type ModalStatusT = {
  isShow: boolean;
  otherParams?: any;
};
export type modalsListT = Record<NameModals, ModalStatusT>;

export type initialStateT = {
  modalsList: modalsListT;
};

const initialState: initialStateT = {
  modalsList: {
    hint: {
      isShow: true,
    },
  },
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TYPE_REDUCER.SET_MODAL: {
      const { nameModal, stateModal, otherParams } = action.payload;
      const paramsModals: ModalStatusT = {
        isShow: stateModal,
        otherParams: otherParams,
      };

      return {
        ...state,
        modalsList: {
          ...state.modalsList,
          [nameModal]: paramsModals,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
