export const Search = ({ ...props }) => {
  return (
    <svg
      {...props}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <g>
        <g id="_x34__4_">
          <g>
            <path
              d="M21.8,20.9l-5.2-5.1c1.4-1.5,2.2-3.4,2.2-5.6c0-4.6-3.8-8.3-8.4-8.3S2,5.7,2,10.3s3.8,8.3,8.4,8.3c2,0,3.8-0.7,5.3-1.8
				l5.2,5.1c0.3,0.2,0.7,0.2,0.9,0C22.1,21.6,22.1,21.2,21.8,20.9z M10.4,17.3c-3.9,0-7.1-3.1-7.1-7s3.2-7,7.1-7
				c3.9,0,7.1,3.1,7.1,7S14.3,17.3,10.4,17.3z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
