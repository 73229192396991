export const Finger = ({ ...props }) => {
  return (
    <svg
      {...props}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path
            d="M13.2,8c-0.2,0-0.4-0.2-0.4-0.4c0-1.5-1.2-2.8-2.8-2.8C8.5,4.7,7.2,6,7.2,7.5C7.2,7.8,7,8,6.8,8C6.5,8,6.3,7.8,6.3,7.5
			c0-2,1.6-3.7,3.7-3.7c2,0,3.7,1.6,3.7,3.7C13.7,7.8,13.5,8,13.2,8z"
          />
        </g>
        <g>
          <path
            d="M14.9,9.5c0,0-0.1,0-0.1,0c-0.2-0.1-0.4-0.3-0.3-0.6c0.1-0.4,0.2-0.9,0.2-1.4c0-2.6-2.1-4.6-4.6-4.6S5.4,5,5.4,7.5
			c0,0.5,0.1,0.9,0.2,1.4c0.1,0.2-0.1,0.5-0.3,0.6C5.1,9.5,4.8,9.4,4.7,9.2C4.6,8.6,4.5,8.1,4.5,7.5C4.5,4.5,7,2,10,2
			s5.5,2.5,5.5,5.5c0,0.6-0.1,1.1-0.2,1.6C15.2,9.3,15.1,9.5,14.9,9.5z"
          />
        </g>
        <g>
          <path
            d="M10.1,6.6c0.5,0,1.1,0.4,1.1,0.9c0,0,0,4,0,4.9c0,0,0,0,0,0v0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2v-0.1
			c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1l0,0.8c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2l0,0c0-0.5,0.5-0.9,1.1-0.9
			c0.6,0,1.1,0.5,1.1,1.1l0,0.8c0,0.1,0.1,0.2,0.2,0.2s0.2-0.1,0.2-0.2l0-0.1c0-0.5,0.5-0.9,1.1-0.9c0.6,0,1.1,0.5,1.1,1.1l0,2
			c0,1.8-0.7,4.6-5.1,4.6c-5.3,0-6-2.7-5.9-5.5c0-0.7,0.6-1.1,1.1-1.1v1.3c0,0.2,0.1,0.2,0.1,0.2s0.1,0,0.1-0.2c0-0.2,0-8,0-8
			C9,7.1,9.5,6.6,10.1,6.6 M10.1,5.7c-1.1,0-2,0.9-2,2v6c-0.7,0.2-1.4,0.9-1.4,1.9c0,1.5,0.1,3.4,1.4,4.7C9.2,21.4,11,22,13.5,22
			c2.2,0,3.9-0.7,4.9-2c0.7-0.9,1.1-2.1,1.1-3.6l0-2c0-1.1-0.9-2-2-2c-0.2,0-0.5,0-0.7,0.1c-0.3-0.6-1-1.1-1.7-1.1
			c-0.3,0-0.5,0.1-0.8,0.1c-0.3-0.6-1-1-1.7-1c-0.2,0-0.4,0-0.5,0.1c0-0.2,0-0.4,0-0.6l0-1.8c0-0.5,0-0.7,0-0.8h0
			C12,6.6,11.1,5.7,10.1,5.7L10.1,5.7z"
          />
        </g>
      </g>
    </svg>
  );
};
