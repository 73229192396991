export const Ar = ({ ...props }) => {
  return (
    <svg
      {...props}
      version="1.1"
      id="_x3C_Layer_x3E_"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
    >
      <g id="AR_x2C__augmented_reality_x2C__mobile_x2C__phone_x2C__reality_x2C__technology">
        <g id="XMLID_52_">
          <g id="XMLID_1389_">
            <path
              d="M12.3,7.1c-0.1,0-0.1,0-0.2,0L12,6.9L11.9,7c-0.2,0.1-0.4,0-0.5-0.1c-0.1-0.2,0-0.4,0.1-0.5l0.3-0.2
				c0.1-0.1,0.2-0.1,0.3,0l0.3,0.2c0.2,0.1,0.2,0.3,0.1,0.5C12.5,7,12.4,7.1,12.3,7.1L12.3,7.1z"
            />
          </g>
          <g id="XMLID_1141_">
            <g id="XMLID_1390_">
              <path
                d="M10.5,16.9c-0.1,0-0.1,0-0.2,0l-0.5-0.3c-0.2-0.1-0.2-0.3-0.1-0.5C9.8,16,10,15.9,10.1,16l0.5,0.3
					c0.2,0.1,0.2,0.3,0.1,0.5C10.7,16.9,10.6,16.9,10.5,16.9z"
              />
            </g>
            <g id="XMLID_1382_">
              <path
                d="M8.9,16c-0.1,0-0.1,0-0.2,0l-0.6-0.3C8,15.6,8,15.4,8,15.2c0.1-0.2,0.3-0.2,0.5-0.1l0.6,0.3c0.2,0.1,0.2,0.3,0.1,0.5
					C9.1,16,9,16,8.9,16z"
              />
            </g>
            <g id="XMLID_1372_">
              <path
                d="M5.9,14.3c-0.1,0-0.1,0-0.2,0L5.2,14C5,13.9,5,13.7,5,13.5c0.1-0.2,0.3-0.2,0.5-0.1l0.6,0.3c0.2,0.1,0.2,0.3,0.1,0.5
					C6.1,14.3,6,14.3,5.9,14.3z"
              />
            </g>
            <g id="XMLID_1142_">
              <path
                d="M4.2,13.4c-0.1,0-0.1,0-0.2,0l-0.5-0.3c-0.2-0.1-0.2-0.3-0.1-0.5c0.1-0.2,0.3-0.2,0.5-0.1l0.5,0.3
					c0.2,0.1,0.2,0.3,0.1,0.5C4.5,13.3,4.3,13.4,4.2,13.4z"
              />
            </g>
          </g>
          <g id="XMLID_987_">
            <g id="XMLID_1139_">
              <path
                d="M20.3,11.6c-0.1,0-0.1,0-0.2,0l-0.5-0.3c-0.2-0.1-0.2-0.3-0.1-0.5c0.1-0.2,0.3-0.2,0.5-0.1l0.5,0.3
					c0.2,0.1,0.2,0.3,0.1,0.5C20.5,11.5,20.4,11.6,20.3,11.6L20.3,11.6z"
              />
            </g>
            <g id="XMLID_1138_">
              <path
                d="M18.7,10.7c-0.1,0-0.1,0-0.2,0L18,10.3c-0.2-0.1-0.2-0.3-0.1-0.5c0.1-0.2,0.3-0.2,0.5-0.1l0.6,0.3
					c0.2,0.1,0.2,0.3,0.1,0.5C19,10.6,18.8,10.7,18.7,10.7L18.7,10.7z"
              />
            </g>
            <g id="XMLID_1080_">
              <path
                d="M15.7,9c-0.1,0-0.1,0-0.2,0L15,8.6c-0.2-0.1-0.2-0.3-0.1-0.5C14.9,8,15.1,7.9,15.3,8l0.6,0.3C16,8.4,16.1,8.6,16,8.8
					C15.9,8.9,15.8,9,15.7,9L15.7,9z"
              />
            </g>
            <g id="XMLID_1079_">
              <path
                d="M14,8c-0.1,0-0.1,0-0.2,0l-0.5-0.3c-0.2-0.1-0.2-0.3-0.1-0.5c0.1-0.2,0.3-0.2,0.5-0.1l0.5,0.3c0.2,0.1,0.2,0.3,0.1,0.5
					C14.3,8,14.2,8,14,8L14,8z"
              />
            </g>
          </g>
          <g id="XMLID_569_">
            <g id="XMLID_985_">
              <path
                d="M13.5,16.9c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2,0-0.4,0.1-0.5l0.5-0.3c0.2-0.1,0.4,0,0.5,0.1c0.1,0.2,0,0.4-0.1,0.5
					l-0.5,0.3C13.6,16.9,13.6,16.9,13.5,16.9L13.5,16.9z"
              />
            </g>
            <g id="XMLID_977_">
              <path
                d="M15.1,16c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2,0-0.4,0.1-0.5l0.6-0.3c0.2-0.1,0.4,0,0.5,0.1c0.1,0.2,0,0.4-0.1,0.5L15.2,16
					C15.2,16,15.1,16,15.1,16L15.1,16z"
              />
            </g>
            <g id="XMLID_931_">
              <path
                d="M18.1,14.3c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2,0-0.4,0.1-0.5l0.6-0.3c0.2-0.1,0.4,0,0.5,0.1c0.1,0.2,0,0.4-0.1,0.5
					l-0.6,0.3C18.2,14.3,18.2,14.3,18.1,14.3L18.1,14.3z"
              />
            </g>
            <g id="XMLID_831_">
              <path
                d="M19.8,13.4c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2,0-0.4,0.1-0.5l0.5-0.3c0.2-0.1,0.4,0,0.5,0.1c0.1,0.2,0,0.4-0.1,0.5
					l-0.5,0.3C19.9,13.4,19.8,13.4,19.8,13.4L19.8,13.4z"
              />
            </g>
          </g>
          <g id="XMLID_565_">
            <g id="XMLID_1395_">
              <path
                d="M3.7,11.6c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2,0-0.4,0.1-0.5l0.5-0.3c0.2-0.1,0.4,0,0.5,0.1c0.1,0.2,0,0.4-0.1,0.5
					l-0.5,0.3C3.8,11.6,3.7,11.6,3.7,11.6z"
              />
            </g>
            <g id="XMLID_1393_">
              <path
                d="M5.3,10.7c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.2,0-0.4,0.1-0.5l0.6-0.3c0.2-0.1,0.4,0,0.5,0.1s0,0.4-0.1,0.5l-0.6,0.3
					C5.4,10.7,5.3,10.7,5.3,10.7L5.3,10.7z"
              />
            </g>
            <g id="XMLID_1391_">
              <path
                d="M8.3,9C8.2,9,8.1,8.9,8,8.8C8,8.6,8,8.4,8.2,8.3L8.7,8c0.2-0.1,0.4,0,0.5,0.1c0.1,0.2,0,0.4-0.1,0.5L8.5,8.9
					C8.5,9,8.4,9,8.3,9L8.3,9z"
              />
            </g>
            <g id="XMLID_1392_">
              <path
                d="M10,8C9.9,8,9.8,8,9.7,7.9c-0.1-0.2,0-0.4,0.1-0.5l0.5-0.3c0.2-0.1,0.4,0,0.5,0.1c0.1,0.2,0,0.4-0.1,0.5L10.2,8
					C10.1,8,10.1,8,10,8L10,8z"
              />
            </g>
          </g>
          <g id="XMLID_1387_">
            <path
              d="M21.4,12.5c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2,0-0.3c-0.1-0.1-0.1-0.2,0-0.3c0.1-0.2,0.3-0.2,0.5-0.1l0.3,0.2
				c0.1,0.1,0.2,0.2,0.2,0.3s-0.1,0.2-0.2,0.3l-0.3,0.2C21.5,12.5,21.4,12.5,21.4,12.5L21.4,12.5z"
            />
          </g>
          <g id="XMLID_1381_">
            <path
              d="M12,17.8c-0.1,0-0.1,0-0.2,0l-0.3-0.2c-0.2-0.1-0.2-0.3-0.1-0.5c0.1-0.2,0.3-0.2,0.5-0.1l0.1,0.1l0.1-0.1
				c0.2-0.1,0.4,0,0.5,0.1c0.1,0.2,0,0.4-0.1,0.5l-0.3,0.2C12.1,17.8,12.1,17.8,12,17.8L12,17.8z"
            />
          </g>
          <g id="XMLID_1378_">
            <path
              d="M2.6,12.5c-0.1,0-0.1,0-0.2,0l-0.3-0.2C2.1,12.2,2,12.1,2,12s0.1-0.2,0.2-0.3l0.3-0.2c0.2-0.1,0.4,0,0.5,0.1
				C3,11.8,3,11.9,2.9,12c0.1,0.1,0.1,0.2,0,0.3C2.9,12.4,2.7,12.5,2.6,12.5z"
            />
          </g>
          <g id="XMLID_1157_">
            <g id="XMLID_1211_">
              <g id="XMLID_1215_">
                <g id="XMLID_1216_">
                  <path
                    d="M15.7,22H8.3c-0.9,0-1.7-0.7-1.7-1.7V3.7C6.7,2.7,7.4,2,8.3,2h7.3c0.9,0,1.7,0.7,1.7,1.7v16.7
							C17.3,21.3,16.6,22,15.7,22z M8.3,2.7c-0.6,0-1,0.4-1,1v16.7c0,0.6,0.4,1,1,1h7.3c0.6,0,1-0.4,1-1V3.7c0-0.6-0.4-1-1-1H8.3z"
                  />
                </g>
              </g>
              <g id="XMLID_1212_">
                <path d="M13,4h-2c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3h2c0.2,0,0.3,0.1,0.3,0.3S13.2,4,13,4z" />
              </g>
            </g>
          </g>
          <g id="XMLID_44_">
            <g id="XMLID_571_">
              <path
                d="M12,12.2c-0.1,0-0.1,0-0.2,0l-3-1.7c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.3l3-1.7c0.1-0.1,0.2-0.1,0.3,0l3,1.7
					c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1-0.1,0.2-0.2,0.3l-3,1.7C12.1,12.2,12.1,12.2,12,12.2z M9.7,10.2l2.3,1.3l2.3-1.3L12,8.9
					L9.7,10.2z"
              />
            </g>
            <g id="XMLID_504_">
              <path
                d="M12,15.8c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.2-0.2-0.2-0.3v-3.7c0-0.1,0.1-0.2,0.2-0.3l3-1.7c0.1-0.1,0.2-0.1,0.3,0
					c0.1,0.1,0.2,0.2,0.2,0.3v3.7c0,0.1-0.1,0.2-0.2,0.3l-3,1.7C12.1,15.8,12.1,15.8,12,15.8z M12.3,12v2.9l2.3-1.3v-2.9L12.3,12z"
              />
            </g>
            <g id="XMLID_502_">
              <path
                d="M12,15.8c-0.1,0-0.1,0-0.2,0l-3-1.7c-0.1-0.1-0.2-0.2-0.2-0.3v-3.7c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.1,0.3,0l3,1.7
					c0.1,0.1,0.2,0.2,0.2,0.3v3.7c0,0.1-0.1,0.2-0.2,0.3C12.1,15.8,12.1,15.8,12,15.8z M9.3,13.6l2.3,1.3V12l-2.3-1.3V13.6z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
