import React, { useEffect, useRef, useState } from "react";
import load3kit from "../../utils/loadThreekit";
import { ModalFrame } from "../modals/ModalFrame/ModalFrame";
import { Interface } from "../Interface/Interface";
import s from "./TestConfigurator.module.scss";
export const TestConfigurator = ({ assetId }: any) => {
  const playerEl = useRef(null);
  const [playerIsLoad, setPlayerIsLoad] = useState(false);

  const currentUrl = window.location.href;


 
  load3kit(() => {
 

    //@ts-ignore
    if (window.threekitPlayer && playerEl.current && !playerIsLoad) {
      window
        //@ts-ignore
        .threekitPlayer({
          threekitUrl: "https://admin-fts.threekit.com/",
          authToken: "5738c59b-a2b1-4b0c-ad51-9d58edeedb5d",
          el: playerEl.current,
          orgId: "015a977b-5927-42f5-aba1-eeb6afd912be",
          // assetId: "959e2b3c-9af9-48b8-8f92-43ddfea73a66",
          assetId: assetId,
          initialConfiguration: {},
          allowMobileVerticalOrbit: true,
          showConfigurator: false,
          showAR: true,
        })
        .then(async function (api: any) {

          await api.when("loaded");
          //@ts-ignore
          window.player = api;
          //@ts-ignore
          window.playerT = await api.enableApi("player");
          //@ts-ignore
          window.configurator = await api.getConfigurator();






          const observer = new MutationObserver((mutationsList) => {
            mutationsList.forEach((mutation) => {
              if (
                mutation.type === "attributes" &&
                mutation.attributeName === "class"
              ) {
                //@ts-ignore
                const id = mutation.target.id;
                const elements = document.querySelectorAll(
                  '[class^="loading___"]'
                );

                if (id === "spin" && elements && elements.length >= 1) {

                  elements.forEach((element) => {
                    //@ts-ignore
                    element.style.zIndex = "999";
                  });
                }
              }
            });
          });

          observer.observe(document.body, {
            attributes: true,
            subtree: true,
            attributeFilter: ["class"],
          });

          setPlayerIsLoad(true);
        });
    }
  });


  return (
    <div className={s.wrapper}>
      {playerIsLoad && <Interface />}
      <div
        ref={playerEl}
        id="player"
        className="player"
        style={{ width: `100%`, height: `100vh` }}
      ></div>
      <ModalFrame />
    </div>
  );
};
