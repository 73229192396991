import React, { useEffect, useRef, useState } from "react";
import s from "./ModalHint.module.scss";
import { ArrowCircle } from "../../../assets/svg/ArrowCircle";
import { Search } from "../../../assets/svg/Search";
import { Ar } from "../../../assets/svg/Ar";
import { Smartphone } from "../../../assets/svg/Smartphone";
import { Finger } from "../../../assets/svg/Finger";
import { setHidenHint } from "../../../utils/urlWork";

export const ModalHint = ({ closeFunc }: any) => {
  const wrapperRef: any = useRef(null);
  const [hideInstructions, setHideInstructions] = useState(false);

  function done() {
    sessionStorage.setItem("hideInstructions", `${hideInstructions}`);
    //@ts-ignore
    console.log('show_got_it_botton = ', window.arUrl);
    debugger
    //@ts-ignore
    if (window.arUrl) {
      let conutClicVariable = 0
      let countClick = sessionStorage.getItem("countClick");
      if (countClick === undefined || countClick === null) {
        sessionStorage.setItem("countClick", `1`);
      } else {
        conutClicVariable = Number(countClick);
        console.log('conutClicVariable', conutClicVariable);

        if (conutClicVariable) {

          sessionStorage.setItem("countClick", `2`);
          conutClicVariable=2
        }
      }
      console.log('conutClicVariable', conutClicVariable);

      const activationAR = sessionStorage.getItem("activationAR");

      //@ts-ignore
      let restoredUrl = window.arUrl;
      //@ts-ignore
      console.log('window.arUrl', window.arUrl);

      // const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      // const isNotIOS = !isIOS;

      if (activationAR === "close") {


        console.log('close');
        //@ts-ignore
        restoredUrl = window.arUrl + '&hintHide=true';
        if (conutClicVariable > 1) {
          restoredUrl = restoredUrl.replace(/[\?&]tkcsid=[^&#]+/g, "")
            .replace(/[\?&]enableAR=true/g, "")
          console.log('##########');
          console.log('restoredUrl', restoredUrl);
          console.log('##########');
          window.history.replaceState({}, document.title, restoredUrl);
          window.location.reload();
        }
        window.location.href = restoredUrl;

      } else if (activationAR === "open" && conutClicVariable > 1) {
        sessionStorage.setItem("activationAR", "close");
        //@ts-ignore
        restoredUrl = window.arUrl + '&hintHide=true';

        restoredUrl = restoredUrl.replace(/[\?&]tkcsid=[^&#]+/g, "").replace(/[\?&]enableAR=true/g, "")
        console.log('##########');
        console.log('restoredUrl', restoredUrl);
        console.log('##########');
        window.history.replaceState({}, document.title, restoredUrl);
        window.location.reload();


      } else {
        console.log('false');
        //@ts-ignore
        restoredUrl = window.arUrl + '&hintHide=true';
        sessionStorage.setItem("activationAR", "open");
        window.location.href = restoredUrl;
        // window.history.replaceState({}, document.title, restoredUrl);
        // window.location.reload();
      }


    } else {

      console.log('test_2');
      if (hideInstructions) {

        window.location.href = window.location.href + '&hintHide=true';
      }

      closeFunc();
    }
  }
  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);

  return (
    <>
      <div ref={wrapperRef} className={s.modal__container}>
        <div className={s.modal_title}>Helpful Hints</div>
        <div className={`${s.item} ${s.mobile}`}>
          <Ar />
          <div className={s.content}>
            <div className={s.text}>
              Point your phone at the floor and slowly scan a wide area left and
              right
            </div>
            <div className={s.subtext}>
              Choose a well lit area and include a horizontal surface in your
              view. Try another area if the model does not load.
            </div>
          </div>
        </div>
        <div className={`${s.item} ${s.mobile}`}>
          <Smartphone />
          <div className={s.content}>
            <div className={s.text}>
              Always view from multiple angles in your space
            </div>
            <div className={s.subtext}>
              When sizing an object in your space, always view it from multiple
              angles to ensure accurate 3D positioning.
            </div>
          </div>
        </div>
        <div className={`${s.item} ${s.mobile}`}>
          <ArrowCircle />
          <div className={s.content}>
            <div className={s.text}>Drag to rotate</div>
            <div className={s.subtext}>
              Move your finger across the screen to rotate the object.
            </div>
          </div>
        </div>
        <div className={`${s.item} ${s.mobile}`}>
          <Search />
          <div className={s.content}>
            <div className={s.text}>Pinch to zoom</div>
            <div className={s.subtext}>
              Place two fingers on the screen and move them toward or away from
              each other to control the zoom.
            </div>
          </div>
        </div>
        <div className={`${s.item} ${s.mobile}`}>
          <Finger />
          <div className={s.content}>
            <div className={s.text}>Double tap to reset</div>
            <div className={s.subtext}>
              Tapping twice will reset the position of the object
            </div>
          </div>
        </div>
        <div className={s.item}>
          <ArrowCircle />
          <div className={s.content}>
            <div className={s.text}>Drag to rotate</div>
            <div className={s.subtext}>
              Click and drag to rotate the object.
            </div>
          </div>
        </div>
        <div className={s.item}>
          <Search />
          <div className={s.content}>
            <div className={s.text}>Scroll to zoom</div>
            <div className={s.subtext}>
              Use the mousewheel or drag two fingers across the mousepad to
              zoom.
            </div>
          </div>
        </div>
        <div className={s.question}>
          <span>
            Have questions?{" "}
            <a
              href="https://www.whalenfurniture.com/augmented-reality-faqs/"
              target="_blank"
            >
              Click here to learn more.
            </a>
          </span>
        </div>
        <div className={s.checkbox__block}>
          <div
            className={`${s.checkbox} ${hideInstructions && s.active}`}
            onClick={() => setHideInstructions(!hideInstructions)}
          >
            <div className={s.inner}></div>
          </div>
          <div className={s.checkbox_text}>
            Do not show these instructions again
          </div>
        </div>
        <div className={s.btn} onClick={done}>
          Got it
        </div>
      </div>
    </>
  );
};
