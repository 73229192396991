import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { TestConfigurator } from "./components/TestConfigurator/TestConfigurator";
import { ModalFrame } from "./components/modals/ModalFrame/ModalFrame";


function App() {
  let search = window.location.search;
  let origin = window.location.origin;
  let searchParams = new URLSearchParams(search);
  let assetId = searchParams.get("assetId");
  const href = `${origin}/?assetId=959e2b3c-9af9-48b8-8f92-43ddfea73a66`;
  let localHideInstructions = sessionStorage.getItem("hideInstructions");
  const sessionArIsReopen = sessionStorage.getItem("arIsReOpening");
  const appHeight = () => {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  // useState(false)

  const openBasePlayerDeforeAR = () => {
    var currentUrl = window.location.href;


    const activationAR = sessionStorage.getItem("activationAR");
    const isARActive = window.location.href.includes("enableAR=true");
    const isFirstOpen = activationAR && activationAR === 'open'

  
    if (isFirstOpen && isARActive) {

      const modifiedUrlToUpdate = currentUrl
        .replace(/[\?&]tkcsid=[^&#]+/g, "")
        .replace(/[\?&]enableAR=true/g, "")
        .replace(/[\?&]hintHide=true/g, "")
 
      //@ts-ignore
      window.arUrl = modifiedUrlToUpdate
      sessionStorage.setItem("activationAR", "close");

      window.history.replaceState({}, document.title, modifiedUrlToUpdate);
      // window.location.replace(modifiedUrlToUpdate)
      window.location.reload();
    }
  }
  


  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
       
        console.log('Вкладка браузера неактивна или минимизирована.');
      } else {
        openBasePlayerDeforeAR()
        console.log('Вкладка браузера активна.');
      }
    }

    const handleBlur = () => {

      console.log('Пользователь покинул вкладку браузера.');
    }

    const handleFocus = () => {
      openBasePlayerDeforeAR()
      console.log('Пользователь вернулся на вкладку браузера.');
    }

    // Добавляем обработчики событий при монтировании компонента
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('blur', handleBlur);
    window.addEventListener('focus', handleFocus);

    // Удаляем обработчики событий при размонтировании компонента
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
    }
  }, []); // Пустой массив зависимостей означает, что эффект будет вызываться только при монтировании и размонтировании компонента


  useEffect(() => {
    // Получаем текущий URL
    var currentUrl = window.location.href;
    console.log('loadPage', currentUrl);
    debugger

    window.addEventListener("resize", appHeight);
    appHeight();

    if (localHideInstructions === null || localHideInstructions === undefined) {
      localHideInstructions = "false"
    }
    // https://whalen-ar.3kit.com/?assetId=8a683aa9-b3c0-4a8e-8064-22652b5cc640&enableAR=true
    // https://whalen-ar.3kit.com/?assetId=959e2b3c-9af9-48b8-8f92-43ddfea73a66&enableAR=true&tkcsid=DtH-Te9-L&hintHide=true
    // Проверяем наличие параметров tkcsid, enableAR и assetId в URL

    const isNotShowHint = !currentUrl.includes("hintHide=true") && localHideInstructions === "false";
    const isShowAR = currentUrl.includes("enableAR=true") && currentUrl.includes("assetId=")
    const activationAR = sessionStorage.getItem("activationAR");
    const isFirstOpen = activationAR && activationAR === 'open'
    console.log('isFirstOpen', isFirstOpen);


    if (isNotShowHint && isShowAR && !isFirstOpen) {
      console.log('test_open');

      // Извлекаем значения параметров из URL
      var urlParams = new URLSearchParams(window.location.search);

      var enableARValue = urlParams.get("enableAR");
      var assetIdValue = urlParams.get("assetId");

      // Удаляем параметры из URL
      const modifiedUrlToUpdate = currentUrl
        .replace(/[\?&]tkcsid=[^&#]+/g, "")
        .replace(/[\?&]enableAR=true/g, "")

      const modifiedUrl = currentUrl
        .replace(/[\?&]tkcsid=[^&#]+/g, "")
        .replace(/[\?&]enableAR=true/g, "")
        .replace(/[\?&]assetId=[^&#]+/g, "")
        .replace("?", "")

      console.log('modifiedUrlToUpdate_loadPadeAR', modifiedUrlToUpdate)
      console.log('modifiedUrl_loadPadeAR', modifiedUrl)
      // Обновляем URL без указанных параметров
      console.log('modifiedUrlToUpdate_load', modifiedUrlToUpdate);

      //@ts-ignore
      window.arUrl =
        modifiedUrl +
        "?assetId=" +
        assetIdValue +
        "&enableAR=" +
        enableARValue



      if (urlParams.get("tkcsid")) {
        let tkcsidValue = urlParams.get("tkcsid");
        //@ts-ignore
        window.arUrl = window.arUrl + "&tkcsid=" + tkcsidValue;
      }





      window.history.replaceState({}, document.title, modifiedUrlToUpdate);
      // window.history.pushState({}, document.title, modifiedUrl)
      // window.location.replace(modifiedUrl)
      // window.location.href= modifiedUrl


      // console.log('arUrl', modifiedUrl +
      //   "?assetId=" +
      //   assetIdValue +
      //   "&enableAR=" +
      //   enableARValue +
      //   "&tkcsid=" +
      //   tkcsidValue)
      // Сохраняем значения параметров в переменной

    }

  }, []);









  //   useEffect(() => {
  //     const handleLocationChange = () => {
  //       var currentUrl = window.location.href;
  //       console.log('remove1',currentUrl)


  //       if (
  //         currentUrl.includes("tkcsid") &&
  //         currentUrl.includes("enableAR=true")
  //       ) {

  //         setTimeout(() => {
  //           // Удаляем параметры из URL
  //           const modifiedUrl = currentUrl.replace(/[\?&]tkcsid=[^&#]+/g, "").replace(/[\?&]enableAR=true/g, "")
  // console.log('remove',modifiedUrl)
  //           window.history.replaceState({}, document.title, modifiedUrl);
  //         }, 2000)

  //       }

  //     };

  //     // Підписатися на подію popstate
  //     window.addEventListener("popstate", handleLocationChange);

  //     return () => {
  //       // Відписатися від події при розмонтуванні компонента
  //       window.removeEventListener("popstate", handleLocationChange);
  //     };
  //   }, []); // Пустий масив залежностей означає, що цей ефект виконається тільки при монтуванні та розмонтуванні



  return (
    <div className="App">
      {assetId ? (
        <TestConfigurator assetId={assetId} />
      ) : (
        <div className="title">
          Set assetId in url, example:
          <br />
          <a href={href}>{href}</a>
        </div>
      )}
    </div>
  );
}

export default App;
